import React from 'react';
import { StaticQuery, graphql, Link } from 'gatsby';
import Layout from '../components/Layout';
import { Banner } from '../acf/Banner';
import SEO from '../components/SEO';
import { decodeEntities } from '../utils/htmlParse';
import { getPageSlugFromWpLink } from '../utils/helpers';
import './sitemap.scss';

const Sitemap = ({ location }) => {
  return (
    <StaticQuery
      query={graphql`
        {
          site {
            siteMetadata {
              siteTitle: title
              wordpressUrl
            }
          }
          allWordpressPage(filter: {slug: {ne: "build-page"}}, sort: {fields: [wordpress_id]}) {
            nodes {
              title
              slug
              link
              parent_element {
                slug
              }
            }
          }
          allWordpressWpProjects(filter: {slug: {ne: "build-project"}}) {
            nodes {
              title
              link
            }
          }
        }
      `}
      render={(data) => {
        const { allWordpressPage, allWordpressWpProjects, site } = data;
        const { siteMetadata: { wordpressUrl, siteTitle } } = site;
        const parentPages = allWordpressPage.nodes.filter(page => page.parent_element === null);
        const sitemapItems = parentPages.map(item => {
          const children = allWordpressPage.nodes.filter(page => {
            if (!page.parent_element) return null;
            return page.parent_element.slug === item.slug;
          });
          return children.length > 0 ? {...item, children} : item;
        });
        return (
          <Layout className="sitemap-page-template">
            <SEO title={`Sitemap | ${siteTitle}`} />
            <Banner
              image={null}
              title="Sitemap"
              wordpressUrl={wordpressUrl}
              hideHeading
            />
            <section className="sitemap">
              <div className="wrapper">
                <nav>
                  {sitemapItems.map(item => {
                    return (
                      <div className="sitemap-item">
                        <Link to={getPageSlugFromWpLink(item.link, wordpressUrl)}>{decodeEntities(item.title)}</Link>
                        {item.children && (
                          <div className="sitemap-item-children">
                            {item.children.map(child => {
                              return (
                                <div className="sitemap-item-child">
                                  <Link
                                    to={getPageSlugFromWpLink(child.link, wordpressUrl)}
                                  >
                                    {decodeEntities(child.title)}
                                  </Link>
                                </div>
                              );
                            })}
                          </div>
                        )}
                        {item.slug === 'showcase' && (
                          <div className="sitemap-item-children">
                            {allWordpressWpProjects.nodes.map(project => {
                              return (
                                <div className="sitemap-item-child">
                                  <Link
                                    to={getPageSlugFromWpLink(project.link, wordpressUrl)}
                                  >
                                    {decodeEntities(project.title)}
                                  </Link>
                                </div>
                              );
                            })}
                          </div>
                        )}
                      </div>
                    );
                  })}
                </nav>
              </div>
            </section>
          </Layout>
        );
      }}
    />
  );
}

export default Sitemap;
